import { CognitoUser } from "@aws-amplify/auth";
import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  LinkProps,
  Tooltip,
  Box, ListItemButton, Chip,
} from "@mui/material";
import {
  AccountCircle,
  Code,
  AccountBox,
  Timer,
  Apps,
  Menu as MenuIcon,
  ChevronLeft, ContentCopy,
} from "@mui/icons-material";
import { AccessTokens } from "../access-tokens/AccessTokens";
import { ApiRequest } from "../api-request/ApiRequest";
import { GroupList } from "../groups/GroupList";
import { PasswordChange } from "../password-change/PasswordChange";
import { MfaSetup } from "../mfa/MfaSetup";
import { RecordingList } from "../recordings/RecordingList";
import { RecordingView } from "../recordings/RecordingView";
import React, { Component } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import whiteLogo from "../../images/dotsButterfly_white.png";
import { UserManagement } from "../user-management/UserManagement";
import { DockConfig } from "../config-dock/DockConfig";
import { DockCalibration } from "../calibrate-dock/DockCalibration";
import { GroupManagement } from "../group-management/GroupManagement";
import { PatientManagement } from "../patient-management/PatientManagement";
import { DockList } from "../dock-health/DockList";
import { Admin } from "../admin/Admin";
import IdleTimer from "react-idle-timer";
import DockIcon from "../../images/Icons/DockIcon";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import Realtime from "../realtime/Realtime";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import "./Home.css";
import { DockDetails } from "../dock-health/DockDetails";
import { DatapointsList } from "../dock-health/DatapointsList";
import { BleDevicesList } from "../dock-health/BleDevicesList";

const drawerWidth = 260;

interface IHomeProps {
  user: CognitoUser | undefined;
  signOut: ((data?: any | undefined) => void) | undefined;
}

interface IHomeState {
  drawerOpen: boolean;
  autoLogout: boolean;
  menuAnchorEl: HTMLElement | null;
  autoLogoutTime: number;
}
export class Home extends Component<IHomeProps, IHomeState> {
  public test = undefined;
  idleTimer: IdleTimer | null;

  constructor(props: IHomeProps) {
    super(props);
    this.idleTimer = null;
    this.state = {
      drawerOpen: true,
      autoLogout: false,
      menuAnchorEl: null,
      autoLogoutTime: this.getAutoLogout(),
    };
  }

  public handleProfileMenuOpen(event: any) {
    this.setState({ menuAnchorEl: event.currentTarget });
  }

  public handleMenuClose() {
    this.setState({ menuAnchorEl: null });
  }

  public render() {
    const isMenuOpen = Boolean(this.state.menuAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={this.state.menuAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={() => this.handleMenuClose()}
      >
        <MenuItem
          onClick={() => this.handleMenuClose()}
          component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, refs) => (
              <Link
                {...props}
                color="primary"
                to="/change-password"
                ref={refs as any}
              />
            )
          )}
        >
          Change Password
        </MenuItem>

        <MenuItem
          onClick={() => this.handleMenuClose()}
          component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, refs) => (
              <Link {...props} color="primary" to="/mfa" ref={refs as any} />
            )
          )}
        >
          Configure MFA
        </MenuItem>
      </Menu>
    );
    if (this.props.user !== undefined) {
      return (
        <BrowserRouter>
          <Box sx={{ display: "flex" }}>
            <IdleTimer
              ref={(ref: IdleTimer) => {
                this.idleTimer = ref;
              }}
              element={document}
              onIdle={() => this.onIdle()}
              timeout={this.state.autoLogoutTime}
            />
            <CssBaseline />
            <AppBar
              position="fixed"
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <Toolbar>
                <Tooltip title={"open/close menu"}>
                  <IconButton
                    color="inherit"
                    aria-label="open/close drawer"
                    onClick={() => {
                      this.setState({ drawerOpen: !this.state.drawerOpen });
                    }}
                    edge="start"
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>

                <img
                  src={whiteLogo}
                  alt="Logo"
                  style={{ width: 40, margin: 5 }}
                />
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Byteflies Cloud
                </Typography>

                <Typography color="inherit">
                  {this.props.user && this.props.user.getUsername()}
                </Typography>
                <IconButton
                  aria-owns={isMenuOpen ? "material-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => this.handleProfileMenuOpen(event)}
                  color="inherit"
                  size="large"
                >
                  <AccountCircle />
                </IconButton>
                <Button
                  color={"secondary"}
                  variant="contained"
                  onClick={() => this.handleSignOut()}
                >
                  Sign out
                </Button>
              </Toolbar>
            </AppBar>
            {renderMenu}
            {this.state.drawerOpen && (
              <Drawer
                variant="permanent"
                sx={{ width: drawerWidth }}
                open={this.state.drawerOpen}
              >
                <Toolbar />
                <div>
                  <Box display="flex" flexDirection="row-reverse">
                    <IconButton
                      onClick={() => {
                        this.setState({ drawerOpen: false });
                      }}
                      size="large"
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Box>
                </div>
                <div>
                  <List
                    component="nav"
                    subheader={
                      <ListSubheader component="div">
                        Recordings
                      </ListSubheader>
                    }
                  >
                    <ListItem button component={Link} to="/groups">
                      <ListItemIcon>
                        <Timer color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={"Recordings"} />
                      <Divider />
                    </ListItem>
                  </List>
                  <List
                    component="nav"
                    subheader={
                      <ListSubheader component="div">API</ListSubheader>
                    }
                  >
                    <ListItemButton component={Link} to="/access-tokens">
                      <ListItemIcon>
                        <Code color="primary"/>
                      </ListItemIcon>
                      <ListItemText primary={"JWT Tokens"}/>
                    </ListItemButton>
                    <ListItemButton onClick={() => {
                      if (this.props.user !== undefined) {
                        navigator.clipboard.writeText(Admin.getIdToken(this.props.user));
                      }
                    }}>
                      <ListItemIcon>
                        <ContentCopy color="primary"/>
                      </ListItemIcon>
                      <ListItemText primary={"Copy ID token to clipboard"}/>
                    </ListItemButton>
                  </List>
                  {this.props.user !== undefined &&
                    Admin.isAdmin(this.props.user) && (
                      <List
                        component="nav"
                        subheader={
                          <ListSubheader component="div">
                            Administrator Menu
                          </ListSubheader>
                        }
                      >
                        <ListItem
                          button
                          component={React.forwardRef<
                            HTMLAnchorElement,
                            Partial<LinkProps>
                          >((props, refs) => (
                            <Link
                              {...props}
                              color="primary"
                              to="/users"
                              ref={refs as any}
                            />
                          ))}
                        >
                          <ListItemIcon>
                            <AccountBox color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"User Management"} />
                          <Divider />
                        </ListItem>
                        <ListItem
                          button
                          component={React.forwardRef<
                            HTMLAnchorElement,
                            Partial<LinkProps>
                          >((props, refs) => (
                            <Link
                              {...props}
                              color="primary"
                              to="/groupmgmt"
                              ref={refs as any}
                            />
                          ))}
                        >
                          <ListItemIcon>
                            <Apps color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"Group Management"} />
                          <Divider />
                        </ListItem>
                        <ListItem
                          button
                          component={React.forwardRef<
                            HTMLAnchorElement,
                            Partial<LinkProps>
                          >((props, refs) => (
                            <Link
                              {...props}
                              color="primary"
                              to="/docks"
                              ref={refs as any}
                            />
                          ))}
                        >
                          <ListItemIcon>
                            <DockIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Docking Stations"} />
                          <Divider />
                        </ListItem>
                        <ListItem
                          button
                          component={React.forwardRef<
                            HTMLAnchorElement,
                            Partial<LinkProps>
                          >((props, refs) => (
                            <Link
                              {...props}
                              color="primary"
                              to="/devices/ble"
                              ref={refs as any}
                            />
                          ))}
                        >
                          <ListItemIcon>
                            <BluetoothIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"SpotCheck Devices"} />
                          <Divider />
                        </ListItem>
                        <ListItem
                          button
                          component={React.forwardRef<
                            HTMLAnchorElement,
                            Partial<LinkProps>
                          >((props, refs) => (
                            <Link
                              {...props}
                              color="primary"
                              to="/realtime"
                              ref={refs as any}
                            />
                          ))}
                        >
                          <ListItemIcon>
                            <PodcastsIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span>
                                Live<span className="beta">Beta</span>
                              </span>
                            }
                          />
                          <Divider />
                        </ListItem>
                      </List>
                    )}
                </div>
              </Drawer>
            )}

            <Box
              component="main"
              sx={{ flexGrow: 1, padding: 5, paddingTop: 10 }}
            >
              <Route exact path="/change-password" component={PasswordChange} />
              <Route exact path="/mfa" component={MfaSetup} />
              <Route exact path="/access-tokens" component={AccessTokens} />
              <Route exact path="/test-api" component={ApiRequest} />
              <Route exact path="/" component={GroupList} />
              <Route exact path="/groups" component={GroupList} />
              <Route
                exact
                path="/groups/:groupId/recordings"
                component={RecordingList}
              />
              <Route
                exact
                path="/groups/:groupId/recordings/:recordingId"
                component={RecordingView}
              />
              <Route
                exact
                path="/groups/:groupId/datapoints"
                component={DatapointsList}
              />
              <Route exact path="/groups/:groupId/docks" component={DockList} />
              <Route exact path="/devices/ble" component={BleDevicesList} />
              <Route
                exact
                path="/groups/:groupId/docks/:dockId"
                component={DockDetails}
              />
              <Route
                exact
                path="/groups/:groupId/patientrecords"
                component={PatientManagement}
              />
              {Admin.isAdmin(this.props.user) && (
                <Route exact path="/users" component={UserManagement} />
              )}

              <Route
                exact
                path="/docks/:dockName/config"
                component={DockConfig}
              />

              {Admin.isAdmin(this.props.user) && (
                <Route
                  exact
                  path="/docks/:dockName/calibration"
                  component={DockCalibration}
                />
              )}

              {Admin.isAdmin(this.props.user) && (
                <Route exact path="/groupmgmt" component={GroupManagement} />
              )}

              {Admin.isAdmin(this.props.user) && (
                <Route exact path="/docks" component={DockList} />
              )}

              <Route exact path="/realtime" component={Realtime} />
            </Box>
          </Box>
        </BrowserRouter>
      );
    } else {
      return null;
    }
  }

  private getAutoLogout(): number {
    const defaultValue = 15 * 60 * 1000;
    const params = new URLSearchParams(window.location.search);
    if (params.has("autologout")) {
      const autologout = params.get("autologout") as string;
      const numeric = parseInt(autologout) * 1000;
      if (numeric && numeric <= defaultValue) {
        return numeric;
      }
    }

    return defaultValue;
  }

  private handleSignOut() {
    if (this.props.signOut !== undefined) {
      this.props.signOut();
    }
  }

  private shouldAutoLogout(user: CognitoUser): boolean {
    const session = user.getSignInUserSession();
    if (session) {
      const groups = session.getIdToken().decodePayload()["cognito:groups"];
      if (groups && groups.includes("AutoLogOut")) {
        return true;
      }
    }
    return false;
  }

  private onIdle() {
    if (this.state.autoLogout) {
      this.handleSignOut();
    }
  }
}
