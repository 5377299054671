import { API } from "@aws-amplify/api";
import moment from "moment";
import {
  Typography,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Link, useHistory, useParams } from "react-router-dom";
import { sortSignal, ListRecordingsResponse } from "../../models/Recording";
import { IRecordingRouteState } from "../../models/RecordingRouteState";
import React, { Fragment, useState } from "react";
import { getGroupById } from "../../api/Groups";
import MaterialTable from "@material-table/core";
import { DateView } from "../utils/DateView";
import { ClipboardItem } from "./ClipboardItem";
import { Alert } from "@mui/material";
import Cookies from "universal-cookie";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Admin } from "../admin/Admin";
import { GroupActionsButton } from "./GroupActionsButton";
import { DeleteRecordingDialog } from "./DeleteRecordingDialog";
import { copyRecording } from "./RecordingActionsButton";
import { RecordingStatusIcon } from "./RecordingStatus";
import { showIcon } from "./SignalList";
import { getDocksOfGroup, getRecording, listRecordings } from "../../api";
import { Search, Launch } from "@mui/icons-material";

const cookies = new Cookies();

interface IRecordingsState {
  startDate: moment.Moment;
  endDate: moment.Moment;
  search?: string;
  dockId?: string;
  dotId?: string;
}

const stateFromSearchParams = () => {
  const newState = {} as IRecordingsState;
  const searchParams = new URLSearchParams(document.location.search);
  const s = Number(searchParams.get("s"));
  if (s && !isNaN(s)) {
    newState.startDate = moment(s).startOf("day");
  } else {
    // startDate is not provided, default is one week ago
    newState.startDate = moment(moment.now())
      .subtract(1, "weeks")
      .startOf("day");
  }
  const e = Number(searchParams.get("e"));
  if (e && !isNaN(e)) {
    newState.endDate = moment(e).endOf("day");
  } else {
    // endDate is not provided, default is today
    newState.endDate = moment(moment.now()).endOf("day");
  }
  const q = searchParams.get("q");
  if (q != null) {
    newState.search = q;
  }
  const dockid = searchParams.get("dockid");
  if (dockid != null) {
    newState.dockId = dockid;
  }
  const dotId = searchParams.get("dotid");
  if (dotId != null) {
    newState.dotId = dotId;
  }

  return newState;
};

interface IPathParams {
  groupId: string;
}

export function RecordingList() {
  const history = useHistory();
  const searchParams = stateFromSearchParams();

  const [recordings, setRecordings] = useState<ListRecordingsResponse[]>();
  const [groupName, setGroupName] = useState<string>();
  const [dockIds, setDockids] = useState<string[]>([]);
  const [dockId, setDockId] = useState(searchParams.dockId);
  const [dotIds, setDotids] = useState<string[]>([]);
  const [dotId, setDotId] = useState(searchParams.dotId);
  const [startDate, setStartDate] = useState<moment.Moment>(
    searchParams.startDate,
  );
  const [endDate, setEndDate] = useState(searchParams.endDate);
  const [search, setSearch] = useState(searchParams.search);
  const [errorText, setErrorText] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(
    Number(cookies.get("pgsize")) || 10,
  );
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteRecordingId, setDeleteRecordingId] = useState<string>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const { groupId } = useParams<IPathParams>();

  React.useEffect(() => {
    if (groupId !== undefined && groupName === undefined) {
      getGroupById(groupId)
        .then((group) => {
          if (group && group.name) {
            setGroupName(group.name);
          }
        })
        .catch((error) => {
          console.error("Error getting group name", error);
          setErrorText("Error getting group name");
        });
    }
  }, [groupName, setGroupName, setErrorText]);

  React.useEffect(() => {
    if (dotId !== undefined && !dotIds.includes(dotId)) {
      setDotids([...dotIds, dotId]);
    }
  }, [dotId, dotIds, setDotids]);

  React.useEffect(() => {
    if (groupId !== undefined && groupName === undefined) {
      getDocksOfGroup(groupId)
        .then((docks) => {
          setDockids(docks);
        })
        .catch((error) => {
          console.error("Error getting docks of group", error);
          setErrorText("Error getting docks of group");
        });
    }
  }, [groupName, setGroupName, setErrorText]);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        setIsAdmin(Admin.isAdmin(user));
      },
      (reject) => {
        console.error(reject);
        setIsAdmin(false);
      },
    );
  }, [setIsAdmin]);

  React.useEffect(() => {
    if (recordings === undefined) {
      const state = stateFromSearchParams();
      getRecordings(state);
    }
  }, [
    recordings,
    startDate,
    endDate,
    search,
    dockId,
    groupId,
    setRecordings,
    setErrorText,
  ]);

  const updateURI = (state: IRecordingsState) => {
    const searchParams = new URLSearchParams();
    searchParams.set("s", "" + state.startDate);
    searchParams.set("e", "" + state.endDate);
    if (state.search !== undefined && state.search !== "") {
      searchParams.set("q", state.search);
    }
    if (state.dockId !== undefined && state.dockId !== "") {
      searchParams.set("dockid", state.dockId);
    }
    if (state.dotId !== undefined && state.dotId !== "") {
      searchParams.set("dotid", state.dotId);
    }

    history.push({ search: searchParams.toString() });
    return searchParams;
  };

  const triggerAlgorithms = (recording: ListRecordingsResponse) => {
    API.put(
      "BytefliesCloudPlatformAPI",
      `groups/${groupId}/recordings/${recording.id}`,
      {
        header: { contentType: "application/json" },
        body: { triggerAlgorithms: true },
      },
    ).then(
      (_response) => {
        console.log("Algorithms requested");
        const state = stateFromSearchParams();
        getRecordings(state);
      },
      (rejected) => {
        console.log(rejected);
        setErrorText(
          "Something went wrong when triggering the algorithms. Please try again.",
        );
      },
    );
  };

  const getRecordings = (state: IRecordingsState) => {
    setErrorText(undefined);
    setRecordings(undefined);

    listRecordings(
      groupId,
      state.startDate,
      state.endDate,
      state.dockId,
      state.dotId,
    ).then(
      (response) => {
        setRecordings(response);

        // When the dock is already removed from the group but used in a recording
        // we want it to show up in the dock list
        for (const recording of response) {
          if (
            recording.dockName !== undefined &&
            !dockIds.includes(recording.dockName)
          ) {
            dockIds.push(recording.dockName);
          }
          if (
            recording.dotId !== undefined &&
            !dotIds.includes(recording.dotId)
          ) {
            dotIds.push(recording.dotId);
          }
        }
      },
      (rejected) => {
        console.log(rejected);
        setRecordings([]);
        setErrorText(
          "Something went wrong while loading the recordings, please try shortening the indicated time period.",
        );
      },
    );
  };

  return (
    <Card style={{ padding: 15 }}>
      <CardHeader
        title={
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/groups`} color="inherit">
              Groups
            </Link>
            <Typography color="textPrimary">{groupName}</Typography>
          </Breadcrumbs>
        }
        action={
          <GroupActionsButton groupId={groupId} groupName={groupName || ""} />
        }
      ></CardHeader>
      <CardContent>
        <Grid
          container
          spacing={1}
          // xs="auto"
          sx={{
            paddingY: 1,
            justifyContent: "right",
          }}
        >
          <Grid item xs={3}>
            <Autocomplete
              multiple={false}
              options={dotIds}
              autoHighlight
              getOptionLabel={(dotId) => dotId}
              value={dotId || undefined}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Dot ID"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(e, value) => {
                if (value === null || value === undefined) {
                  setDotId(undefined);
                  const state: IRecordingsState = {
                    startDate: startDate,
                    endDate: endDate,
                    search: search,
                    dockId: dockId,
                    dotId: undefined,
                  };
                  updateURI(state);
                  getRecordings(state);
                } else {
                  setDotId(value);
                  const state: IRecordingsState = {
                    startDate: startDate,
                    endDate: endDate,
                    search: search,
                    dockId: dockId,
                    dotId: value,
                  };
                  updateURI(state);
                  getRecordings(state);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              multiple={false}
              options={dockIds}
              autoHighlight
              getOptionLabel={(dockId) => dockId}
              value={dockId || undefined}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Dock ID"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(e, dockId) => {
                if (dockId === null || dockId === undefined) {
                  setDockId(undefined);
                  const state: IRecordingsState = {
                    startDate: startDate,
                    endDate: endDate,
                    search: search,
                    dockId: undefined,
                    dotId: dotId,
                  };
                  updateURI(state);
                  getRecordings(state);
                } else {
                  setDockId(dockId);
                  const state: IRecordingsState = {
                    startDate: startDate,
                    endDate: endDate,
                    search: search,
                    dockId: dockId,
                    dotId: dotId,
                  };
                  updateURI(state);
                  getRecordings(state);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={startDate.toDate()}
                onChange={(date) => {
                  if (date) {
                    const sd = moment(date).startOf("day");
                    setStartDate(sd);
                    const state: IRecordingsState = {
                      startDate: sd,
                      endDate: endDate,
                      search: search,
                      dockId: dockId,
                      dotId: dotId,
                    };
                    updateURI(state);
                    getRecordings(state);
                  }
                }}
                maxDate={endDate.toDate()}
                renderInput={(params) => (
                  <TextField fullWidth={true} {...params} size="small" />
                )}
                inputFormat="dd/MM/yyyy"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={endDate.toDate()}
                onChange={(date) => {
                  if (date) {
                    const ed = moment(date).endOf("day");
                    setEndDate(ed);
                    const state: IRecordingsState = {
                      startDate: startDate,
                      endDate: ed,
                      search: search,
                      dockId: dockId,
                      dotId: dotId,
                    };
                    updateURI(state);
                    getRecordings(state);
                  }
                }}
                minDate={startDate.toDate()}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth={true} />
                )}
                inputFormat="dd/MM/yyyy"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {recordings ? (
          <MaterialTable
            data-cy="recordings"
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    {errorText !== undefined ? (
                      <Alert severity="error" style={{ width: "fit-content" }}>
                        {errorText}
                      </Alert>
                    ) : (
                      <span>
                        No recordings found with the given criteria. Please
                        expand the time range or search term
                      </span>
                    )}
                  </div>
                ),
              },
            }}
            columns={[
              {
                title: "Link",
                field: "id",
                type: "string",
                render: (rowData) => (
                  <a
                    href={`/groups/${groupId}/recordings/${rowData.id}`}
                    style={{ color: "#777" }}
                  >
                    <Launch fontSize={"small"} />
                  </a>
                ),
                width: 20,
                disableClick: true,
              },
              {
                title: "Start of Recording",
                field: "startDate",
                type: "datetime",
                render: (rowData) => (
                  <ClipboardItem
                    title={String(rowData.startDate)}
                    label={`${DateView.toString(
                      new Date((rowData.startDate || 0) * 1000),
                    )}`}
                  />
                ),
              },
              {
                title: "ID",
                field: "id",
                render: (rowData) => (
                  <ClipboardItem
                    data-cy={`recording-id-${rowData.id}`}
                    title={rowData.id}
                    label={`${rowData.id.slice(0, 8)}..`}
                  />
                ),
              },
              {
                title: "Dot",
                field: "dotId",
                render: (rowData) => (
                  <ClipboardItem
                    title={rowData.dotId}
                    label={`..${rowData.dotId.slice(-6)}`}
                  />
                ),
              },
              {
                title: "Dock",
                field: "dockName",
                render: (rowData) => (
                  <ClipboardItem
                    title={rowData.dockName}
                    label={`..${rowData.dockName.slice(-6)}`}
                  />
                ),
              },
              {
                title: "Patient",
                field: "patient",
                cellStyle: {
                  textAlign: "left",
                },
                render: (rowData) => (
                  <ClipboardItem
                    title={rowData.patient}
                    label={`${
                      rowData.patient.length > 15
                        ? `${rowData.patient.slice(0, 15)}..`
                        : rowData.patient
                    }`}
                  />
                ),
              },
              {
                title: "Signals",
                customFilterAndSearch: (filter, rowData, columDef) => {
                  return rowData.signals.some((signal) =>
                    signal.type.includes(filter),
                  );
                },
                render: (rowData) => {
                  return (
                    <Fragment>
                      <RecordingStatusIcon
                        groupId={groupId}
                        recordingId={rowData.id}
                        status={rowData.status}
                      />
                      {rowData.signals
                        .sort((s1, s2) => sortSignal(s1, s2))
                        .filter(
                          (s) =>
                            s.type !== "BAT" &&
                            s.type !== "LEAD_OFF" &&
                            s.type !== "EVENT" &&
                            s.type !== "SYNC",
                        )
                        .map((signal, i) => {
                          if (signal.type) {
                            return (
                              <IconButton
                                key={i}
                                disabled={
                                  groupId === undefined ||
                                  rowData === undefined ||
                                  rowData.id === undefined ||
                                  signal.id === undefined
                                }
                                onClick={async (event) => {
                                  event.stopPropagation();
                                  try {
                                    const recording = await getRecording(
                                      groupId,
                                      rowData.id,
                                    );
                                    for (const s of recording.signals) {
                                      if (
                                        s.id === signal.id &&
                                        s.rawData !== undefined
                                      ) {
                                        event.stopPropagation();
                                        window.location.href = s.rawData!;
                                      }
                                    }
                                  } catch (error) {
                                    console.error(
                                      "Unable to download signal",
                                      error,
                                    );
                                  }
                                }}
                                size="large"
                              >
                                {showIcon(signal.type, signal.quality)}
                              </IconButton>
                            );
                          }
                          return "";
                        })}
                    </Fragment>
                  );
                },
              },
              {
                title: "Duration",
                field: "duration",
                render: (rowData) => DateView.secondsToHms(rowData.duration),
              },
            ]}
            data={recordings}
            options={{
              pageSizeOptions: [5, 10, 25, 100, 250],
              pageSize: pageSize,
              showTitle: false,
              searchText: search,
              actionsColumnIndex: -1,
              padding: "dense",
              filtering: false,
            }}
            onPageChange={(page, s) => {
              setPageSize(s);
            }}
            onRowClick={(_, rowData) => {
              if (rowData) {
                history.push({ pathname: `recordings/${rowData.id}` }, {
                  groupName: groupName,
                  queryParams: document.location.search,
                } as IRecordingRouteState);
              }
            }}
            onSearchChange={(searchText) => {
              setSearch(searchText);
              const state: IRecordingsState = {
                startDate: startDate,
                endDate: endDate,
                search: searchText,
                dockId: dockId,
                dotId: dotId,
              };
              updateURI(state);
            }}
            onRowsPerPageChange={(pagesize: number) => {
              cookies.set("pgsize", pagesize);
            }}
            actions={
              isAdmin
                ? [
                    {
                      icon: "delete",
                      iconProps: { color: "primary" },
                      tooltip: "Delete Recording",
                      onClick: (_event, rowData) => {
                        setDeleteRecordingId(
                          (rowData as ListRecordingsResponse).id,
                        );
                        setDeleteOpen(true);
                      },
                    },
                    {
                      icon: "autorenew",
                      iconProps: { color: "primary" },
                      tooltip: "Trigger algorithms",
                      onClick: (_event, rowData) => {
                        const recording = rowData as ListRecordingsResponse;
                        triggerAlgorithms(recording);
                      },
                    },
                    {
                      icon: "content_copy",
                      iconProps: { color: "primary" },
                      tooltip: "Copy metadata",
                      onClick: (_event, rowData) => {
                        copyRecording(rowData as ListRecordingsResponse);
                      },
                    },
                  ]
                : []
            }
          />
        ) : (
          <div style={{ textAlign: "center", width: "100%", height: 100 }}>
            <CircularProgress color="inherit" />
          </div>
        )}
        <DeleteRecordingDialog
          open={deleteOpen}
          groupId={groupId}
          recordingId={deleteRecordingId || ""}
          onClose={() => {
            setDeleteOpen(false);
            const state: IRecordingsState = {
              startDate: startDate,
              endDate: endDate,
              search: search,
              dockId: dockId,
              dotId: dotId,
            };
            getRecordings(state);
          }}
        />
      </CardContent>
    </Card>
  );
}
