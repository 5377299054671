import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useHistory, useParams, Link } from "react-router-dom";
import { API } from "@aws-amplify/api";
import {
  Typography,
  Breadcrumbs,
  Grid,
  Button,
  Tooltip,
  Alert,
} from "@mui/material";
import {
  Settings,
  LocationSearching,
  PermScanWifi,
  SystemUpdate,
} from "@mui/icons-material";
import React, { useState } from "react";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { DateView } from "../utils/DateView";
import { Admin } from "../admin/Admin";
import { DeviceInfoDialog } from "./DeviceInfoDialog";
import MaterialTable from "@material-table/core";
import { DockConnection, getDockConnections, putWifiScan } from "../../api";
import {NO_GROUP} from "../utils/noGroup";

interface ApiGroup {
  id: string;
  name?: string;
  description?: string;
}

interface IPathParams {
  groupId?: string;
}

interface DockSelection extends DockConnection {
  initialState: boolean;
  selected: boolean;
}

interface DockListProps {}

export function DockList(props: DockListProps) {
  const { groupId } = useParams<IPathParams>();
  const history = useHistory();
  const [docks, setDocks] = useState<DockSelection[]>([]);
  const [searchString, setSearchString] = useState<string>();
  const [groups, setGroups] = useState<ApiGroup[]>([]);
  const [apiError, setApiError] = useState<Error | undefined>();
  const [groupName, setGroupName] = useState<string>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [dockUpdateStatusOpen, setDockUpdateStatusOpen] = useState(false);
  const [selectedDockId, setSelectedDockId] = useState<string | undefined>();
  const [inProgress, setInProgress] = useState(false);

  const getDocks = async (
    groupId: string | undefined,
    dockId: string | undefined
  ) => {
    setInProgress(true);

    try {
      const docks = await getDockConnections(groupId, dockId);
      const dockSelection = docks.map((dock) => dock as DockSelection);
      for (const dock of dockSelection) {
        dock.selected = false;
        dock.initialState = true;
      }
      setDocks(dockSelection);
      setApiError(undefined);
    } catch (error) {
      const err = error as Error;
      console.error(err);
      setDocks([]);
      setApiError(err);
    } finally {
      setInProgress(false);
    }
  };

  const getGroups = async () => {
    try {
      const response = await API.get(
        "BytefliesCloudPlatformAPI",
        `admin/groups`,
        {}
      );
      if (response && response instanceof Array) {
        const groups = response as ApiGroup[];
        setGroups(groups);
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error(error);
      setGroups([]);
    }
  };

  React.useEffect(() => {
    if (groups === undefined || groups.length === 0) {
      getGroups();
    }
  }, [groups, setGroups]);

  React.useEffect(() => {
    if (
      !inProgress &&
      groupId !== undefined &&
      (docks === undefined || docks.length === 0)
    ) {
      getDocks(groupId, searchString);
    }
  }, [docks, setDocks, setInProgress, inProgress, setApiError, groupId]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!inProgress) {
        getDocks(groupId, searchString);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [docks, setDocks, setInProgress, inProgress, setApiError, groupId]);

  React.useEffect(() => {
    if (groupId !== undefined || groupName === undefined) {
      API.get("BytefliesCloudPlatformAPI", `groups`, {}).then((response) => {
        if (response && response instanceof Array) {
          const found = response.find((element) => element.groupId === groupId);
          if (found) {
            setGroupName(found.groupName);
          }
        }
      });
    }
  }, [groupId, setGroupName]);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        setIsAdmin(Admin.isAdmin(user));
      },
      (reject) => {
        console.error(reject);
        setIsAdmin(false);
      }
    );
  }, []);

  return (
    <>
      {apiError && (
        <Alert severity="error">
          Something went wrong loading the docking stations.
        </Alert>
      )}

      <Grid container spacing={1}>
        {groupId !== undefined && (
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ padding: 15 }}>
              <Link to={`/groups`} color="inherit">
                Groups
              </Link>
              <Typography color="textPrimary">
                {groupName || groupId}
              </Typography>
            </Breadcrumbs>
          </Grid>
        )}

        <Grid item xs={12}>
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage:
                  docks === undefined || docks.length === 0
                    ? `Enter a Docking Station id`
                    : `No Docking Stations found.`,
              },
            }}
            isLoading={inProgress}
            columns={[
              {
                title: "Online",
                field: "connectivity",
                render: (dock) =>
                  dock.connectivity ? (
                    <CloudDoneIcon color="primary" />
                  ) : (
                    <CloudOffIcon color="primary" />
                  ),
              },
              {
                title: "Dock ID",
                field: "thingName",
                render: (dock) => (
                  <Link to={`/groups/${dock.groupId}/docks/${dock.thingName}`}>
                    {dock.thingName}
                  </Link>
                ),
              },
              {
                title: "Last Connection",
                field: "timestamp",
                render: (dock) =>
                  dock.timestamp
                    ? DateView.timestampToStringFull(dock.timestamp)
                    : "",
              },
              {
                title: "Firmware",
                field: "firmwareVersion",
                render: (dock) => (
                  <Button
                    onClick={() => {
                      setSelectedDockId(dock.thingName);
                      setDockUpdateStatusOpen(true);
                    }}
                  >
                    {dock.firmwareVersion || ""}

                    {dock.firmwareVersion !== undefined &&
                      dock.firmwareVersion !== "" &&
                      dock.desiredFirmwareVersion !== undefined &&
                      dock.desiredFirmwareVersion !== "" &&
                      dock.desiredFirmwareVersion !== dock.firmwareVersion && (
                        <Tooltip
                          title={`Desired firmware version is ${dock.desiredFirmwareVersion}`}
                        >
                          <SystemUpdate />
                        </Tooltip>
                      )}
                  </Button>
                ),
              },
              {
                title: "Group",
                field: "groupId",
                render: (dock) => {
                  const group = groups
                    .filter(
                      (g) => g.name !== NO_GROUP
                    )
                    .find((g) => g.id === dock.groupId);

                  return group === undefined || group.name === undefined
                    ? dock.groupId
                    : group.name;
                },
              },
            ]}
            data={docks}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: true,
              selectionProps: (dock: DockSelection) => ({
                checked: dock.selected === undefined ? false : dock.selected,
              }),
              actionsColumnIndex: -1,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
            onSelectionChange={(_, dock: DockSelection | undefined) => {
              if (dock !== undefined) {
                dock.selected = !dock.selected;
                setDocks([...docks]);
              }
            }}
            onSearchChange={async (s) => {
              setSearchString(s);
              if (s === undefined || s === null || s.length < 5) {
                // The dockId parameter is needed
                return;
              }

              await getDocks(groupId, s);
            }}
            actions={[
              {
                icon: () => <Settings color="primary" />,
                tooltip: "Configures the docking station",
                onClick: (_, docks) => {
                  const dockId = Array.isArray(docks)
                    ? docks[0].thingName
                    : docks.thingName;
                  history.push(`/docks/${dockId}/config`);
                },
              },
              (dock) => ({
                icon: () => <LocationSearching color="primary" />,
                tooltip: "Calibrates the docking station",
                onClick: (_, docks) => {
                  const dockId = Array.isArray(docks)
                    ? docks[0].thingName
                    : docks.thingName;
                  history.push(`/docks/${dockId}/calibration`);
                },
                disabled: !isAdmin,
              }),
              {
                icon: () => <PermScanWifi color="primary" />,
                tooltip: "Schedules a job to perform a WiFi scan",
                onClick: async (_, docks) => {
                  try {
                    const dockId = Array.isArray(docks)
                      ? docks[0].thingName
                      : docks.thingName;
                    const job = await putWifiScan(dockId);
                    console.info("wifi scan job", JSON.stringify(job));
                  } catch (error) {
                    console.error("Error starting a WiFI scan");
                  }
                },
                disabled: !isAdmin,
              },
            ]}
          />
        </Grid>
      </Grid>

      <DeviceInfoDialog
        dockId={selectedDockId || ""}
        open={dockUpdateStatusOpen}
        onClose={() => {
          setDockUpdateStatusOpen(false);
        }}
      />
    </>
  );
}
